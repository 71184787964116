@import "~utils/variables";

.wrapper {
  width: 100%;
  height: $navbarHeight;
  border-bottom: 1px solid rgba(1, 1, 1, 0.2);
  box-shadow: 0px 1px 10px rgba(1, 1, 1, 0.1);
  
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    cursor: pointer;
  }

  .profileContainer {
    display: flex;
    align-items: center;

    svg {
      font-size: 2.4rem;
      color: rgba(1, 1, 1, 0.4);
      margin-right: 0.2em;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span:last-child {
        font-size: 0.9rem;
        color: blue;
        cursor: pointer;
        font-weight: 500;
      }
    }
  }
}