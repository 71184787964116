@import "~utils/variables";

.wrapper {
  display: flex;
  justify-content: center;
  padding-top: 10em;

  form {
    width: 400px;

    div.row {
      width: 100%;
      margin-bottom: 1em;

      label {
        display: inline-block;
        margin-bottom: 0.5em;
      }

      input {
        width: 100%;
        height: 35px;
        border: 1px solid rgba(1, 1, 1, 0.2);
        border-radius: 5px;
        padding-left: 1em;
      }

      button {
        background-color: $primaryColor;
        width: 10em;
        height: 35px;
        color: white;
        border-radius: 5px;
      }
    }
  }
}
