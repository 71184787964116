@import "~utils/variables";

.wrapper {
  padding-top: 2em;

  h4 {
    margin-bottom: 2em;
    margin-top: 2em;
  }

  .row {
    margin-bottom: 2.2em;
    display: flex;
    flex-direction: column;
    max-width: 60em;

    label {
      display: inline-block;
      margin-bottom: 0.3em;
      font-size: 0.9rem;
    }

    input {
      height: 40px;
      width: 350px;
      border-radius: 10px;
      border: 1px solid rgba(1, 1, 1, 0.2);
      padding-left: 1em;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  button {
    height: 40px;
    width: 8em;
    border-radius: 5px;
    background-color: $primaryColor;
    color: white;
  }
}
