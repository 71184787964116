* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;

  button {
    border: none;
    cursor: pointer;
  }

  .rdw-editor-wrapper {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
  }
}

.rdw-editor-main {
  padding-left: 1em;
}

#questionEditor {
  .public-DraftEditor-content {
    min-height: 10em;
    max-height: 20em;
  }
}

#choiceEditor {
  .public-DraftEditor-content {
    min-height: 5em;
    max-height: 10em;
  }
}

#instructionEditor {
  .public-DraftEditor-content {
    height: 40em;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
